import React from 'react'
import { graphql } from 'gatsby'
import ServicesProgrammingTemplate from '../../components/pages/servicesProgrammingTemplate'

const OurServicesSk = ({data}) => (
  <ServicesProgrammingTemplate data={data} lang={'sk'}/>
)
export const query = graphql`{
    programming: file(relativePath: {eq: "pages/sk/services/programming.md"}) {
        childMarkdownRemark {
            frontmatter {
                language
                title
                subtitle
                description
                services {
                    title
                    subtitle
                    description
                    icon {
                        publicURL
                    }
                    service
                }
            }
        }
    }
}
`

export default OurServicesSk
