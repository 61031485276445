import React, { Component } from 'react'
import Layout from '../layout'
import { getTranslateFunction } from '../../utilities'
import { TranslatedLink } from '../TranslatedLink'
import ContactForm from '../contactForm/ContactForm'
import ReactVivus from 'react-vivus'
import LinkBtn from '../linkBtn/LinkBtn'
import SideMenuServices from '../sideMenu/side-menu-services'
import PageTitle from '../pageTitle'
import Reveal from '../reveal'
import { convertNonBreakingSpace } from '../../utilities'
import './Pages.scss';
import ContactUs from '../contactUs/ContactUs'

class ServicesProgrammingTemplate extends Component {

  render() {
    const { data, lang } = this.props
    const programming = data.programming.childMarkdownRemark.frontmatter

    const t = getTranslateFunction(lang)
    return (
      <Layout lang={lang} className={'mobile-menu-offset'}>
        <section className="about-us-top margin-top">
          <div className="container">
            <div className="row subpage-title align-items-center fix-nav-offset">
              <div className="col-md-6 pr-0 pr-md-3 pr-lg-5">
                <PageTitle title={programming.title} description={programming.description} url="https://narative.sk/sluzby" />
                <p dangerouslySetInnerHTML={{ __html: convertNonBreakingSpace(programming.subtitle) }}></p>
              </div>
              <div className="col-md-6 pl-md-3">
                <p className="description-smaller pr-0" dangerouslySetInnerHTML={{ __html: programming.description }}></p>
              </div>
            </div>
          </div>
        </section>


        <section className="references mt-md-5 pt-md-5">
          <div className="d-md-flex">
            <div className="d-none d-lg-block col-lg-3 side-menu-height-reference" id="left">
              <SideMenuServices lang={lang} isGhost={false} />
            </div>
            <div className=" col-lg-9 our-services-block">
              {programming.services.map((service, i) => {
                const slug = service.service

                return (
                  <Reveal otherClassName="row align-items-center item" key={i}>
                    <div className="col-lg-4 d-flex justify-content-md-center mt-4 mt-lg-0 mb-md-5 mb-lg-0">
                      <ReactVivus
                        className={'vivus'}
                        id={service.title}
                        option={{
                          start: `${i === 0 ? 'autostart' : 'inViewport'}`,
                          delay: 0,
                          file: service.icon.publicURL,
                          animTimingFunction: 'EASE',
                          type: 'delayed',
                          duration: 100,
                        }}
                        style={{ height: '256px', width: '256px', pointerEvents: 'none' }}
                      />
                      {/*<img src={service.icon.publicURL} alt={service.title}/>*/}
                    </div>
                    <div className="col-lg-6">
                      <TranslatedLink to={slug}>
                        <h2>{service.title}</h2>
                      </TranslatedLink>
                      <p className="description-smaller">{service.subtitle}</p>
                      <p>{service.description}</p>
                      <LinkBtn>
                        <TranslatedLink to={slug}>{t('OurServices.OpenBtn')}</TranslatedLink>
                      </LinkBtn>
                    </div>
                  </Reveal>
                )
              })}
            </div>
          </div>
        </section>

        <ContactUs
          blockClassName={'services'}
          LeftSideTitle={t('References.MoreProjects.Title')}
          LeftSideSubTitle={'Dizajn'}
          LeftSideLink={'/en/services/design'}
          linkRight="/en/contact"
          rightSecondLink="/en/brief"
        />

       {/*  <Reveal>
          <ContactForm />
        </Reveal> */}
      </Layout>
    )
  }
}

export default ServicesProgrammingTemplate
