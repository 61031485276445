import React, {PureComponent } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Select from 'react-select'
import { TranslatedLink } from './../TranslatedLink'
import { getTranslateFunction } from '../../utilities'
import { LangContext } from './../../layouts/index'
import * as PropTypes from 'prop-types'
import LinkBtn from './../linkBtn/LinkBtn'
//import { verifyCaptcha } from '../../captcha'
import HoverBtn from './../HoverBtn'
import { isBrowser } from '../../utilities'
import './ContactForm.scss'


const blankFormData = {
  email: '',
  gdpr: '',
  howDidYouHearAboutUs: '',
  message: '',
  name: '',
  tel: '',
}

export class Form extends PureComponent {
  state = {
    selectedOption: null,
    inputValues: blankFormData,
    errorMessage: '',
    errorMessageSuccess: false,
  }

  constructor(props) {
    super(props)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getFormData = this.getFormData.bind(this)
    this.formRef = React.createRef()
    this.submit = this.submit.bind(this)
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption })
  }

  handleInputChange = (event) => {
    const target = event.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value

    this.setState((prevState) => {
      return { inputValues: { ...prevState.inputValues, [name]: value } }
    })
  }

  validateForm() {
    // const t = getTranslateFunction(this.props.lang)
    //
    // if (isEmpty(this.state.inputValues['email']) && isEmpty(this.state.inputValues['tel'])) {
    //   this.setState({ errorMessage: t('Contact.ErrorMessages.NoEmailOrTel') })
    //   return false
    // }
    // if (isEmpty(this.state.inputValues['message'])) {
    //   this.setState({ errorMessage: t('Contact.ErrorMessages.EmptyMessage') })
    //   return false
    // }
    // this.setState({ errorMessage: '' })
    // return true
  }

  getFormData = object => Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key])
    return formData
  }, new FormData())

  async submit() {
    if (this.formRef != null && this.formRef.current.reportValidity()) {
      const service = this.state.selectedOption != null ? this.state.selectedOption.value : 'Not selected'

      //  const captchaResponse = await verifyCaptcha();
      const data = {
        'service': service,
        'name': this.state.inputValues.name,
        'email': this.state.inputValues.email,
        'number': this.state.inputValues.tel,
        'message': this.state.inputValues.message,
        'howDidYouHearAboutUs': this.state.inputValues.howDidYouHearAboutUs,
        'gdpr': this.state.inputValues.gdpr,
        'g-recaptcha-response': true,
        //'g-recaptcha-response': captchaResponse,
      }
      const url = 'https://api.narative.sk/api/contact-form'


        if (typeof window !== 'undefined') {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'contactFormSubmit',
          });
        }

      let resp = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(data),
      });
      const jsonRes = await resp.json();
      if (resp.status !== 200) {
        this.setState({
          errorMessageSuccess: null,
          errorMessage: resp.status === 422 ? Object.values(jsonRes.errors)[0] : "Server Error",
        })
      } else {
        this.setState({
          errorMessageSuccess: true,
          selectedOption: null,
          errorMessage: '',
          inputValues: blankFormData,
        })
      }


      // return await res.json();

    }
  }
  resize(ara) {
    ara.style.height = 'auto';
    ara.style.height = ara.scrollHeight + 'px';
  }
  addEvent(el, types, fn) {
    if (!(el)) return
    if (typeof types === 'string') types = [types];

    types.forEach(type => el.addEventListener(type, fn));
  }
  removeEvent(el, types, fn) {
    if (!(el)) return
    if (typeof types === 'string') types = [types];
    types.forEach(type => el.removeEventListener(type, fn));
  }
  componentDidMount() {
    if (isBrowser()) {
      var autosize = document.getElementsByClassName('autosize')[0];
      this.addEvent(autosize, ['change', 'cut', 'paste', 'keydown'], e => this.resize(autosize));
    }
  }
  componentWillUnmount() {
    if (isBrowser()) {
      var autosize = document.getElementsByClassName('autosize')[0];
      this.removeEvent(autosize, ['change', 'cut', 'paste', 'keydown'], e => this.resize(autosize))
    }
  }


  render() {
    const { selectedOption } = this.state
    const { data, lang } = this.props
    const t = getTranslateFunction(lang)

    const options = [
      { value: '-', label: t('Contact.ServiceOptions.Webpages'), isDisabled: true },
      { value: 'Webstránky - Firemný web', label: t('Contact.ServiceOptions.WebpagesCorporate') },
      { value: 'Webstránky - E-shop', label: t('Contact.ServiceOptions.WebpagesEshop') },
      { value: 'Webstránky - Portál', label: t('Contact.ServiceOptions.WebpagesPortal') },
      { value: 'Webstránky - Microsite', label: t('Contact.ServiceOptions.WebpagesMicrosite') },
      { value: 'Webstránky - CRM', label: t('Contact.ServiceOptions.WebpagesCRM') },
      { value: '-', label: t('Contact.ServiceOptions.Applications'), isDisabled: true },
      { value: 'Aplikácie - Android', label: t('Contact.ServiceOptions.ApplicationsAndroid') },
      { value: 'Aplikácie - iOS', label: t('Contact.ServiceOptions.ApplicationsIos') },
      { value: 'Aplikácie - Multi-platform', label: t('Contact.ServiceOptions.ApplicationsMultiPlatform') },
      { value: '-', label: t('Contact.ServiceOptions.Graphic'), isDisabled: true },
      { value: 'Grafika - Logo/Branding', label: t('Contact.ServiceOptions.GraphicLogoBranding') },
      { value: 'Grafika - Digital/Print', label: t('Contact.ServiceOptions.GraphicDigitalPrint') },
      { value: '-', label: t('Contact.ServiceOptions.OtherServices'), isDisabled: true },
      { value: 'Dalšie služby - Programovanie', label: t('Contact.ServiceOptions.OtherServicesProgramming') },
      { value: 'Dalšie služby - Servis', label: t('Contact.ServiceOptions.OtherServicesServis') },
      { value: 'Dalšie služby - Marketing/SEO', label: t('Contact.ServiceOptions.OtherServicesMarketingSEO') },
      { value: 'Dalšie služby - Iné', label: t('Contact.ServiceOptions.OtherServicesOther') },
    ]

    const contact = (lang === 'sk') ? data.contactSk.childMarkdownRemark.frontmatter : data.contactEn.childMarkdownRemark.frontmatter
    return (
      <section className={`contact-block ${this.props.noBackground === true ? '' : 'contact-hp'}`} id='contactForm'>
        <div className='container'>
          <div className="row">
            <div className="col-12 col-lg-6 formular">
              <div>
                <h2>{t('Contact.Title')}</h2>
                    <form id="contact-form"
                      name="contact"
                      method="POST"
                      ref={this.formRef}
                      onSubmit={e => e.preventDefault()}>
                      <div className="form-group mb-0 mb-md-3">
                        <Select
                          className="contact-select"
                          classNamePrefix={'contact-select'}
                          options={options}
                          isSearchable={false}
                          placeholder={t('Contact.ServicePlaceholder')}
                          data-testid="select"
                          value={selectedOption}
                          onChange={this.handleChange}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#f6f6f6',
                              primary: 'transparent',
                            },
                          })}
                        />
                      </div>

                      <div className="form-group mb-0 mb-md-3">
                        <input
                          className="form-control"
                          type="text"
                          data-testid="name"
                          name="name"
                          placeholder={t('Contact.NameAndSurname')}
                          value={this.state.inputValues.name}
                          onChange={this.handleInputChange}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                        />
                      </div>

                      {/* Email and Phone number */}
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group mb-0 mb-md-3">
                            <input
                              className="form-control form-required"
                              type="email"
                              name="email"
                              data-testid="email"
                              required={true}
                              value={this.state.inputValues.email}
                              onChange={this.handleInputChange}
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                            />
                            <div className="required-placeholder">
                              {t('Contact.Email')}
                              <span className="asterisk">*</span>
                            </div>
                          </div>
                        </div>
                        {/* Phone number */}
                        <div className="col-lg-6">
                          <div className="form-group mb-0 mb-md-3">
                            <input
                              className="form-control"
                              type="text"
                              name="tel"
                              placeholder={t('Contact.TelNumber')}
                              value={this.state.inputValues.tel}
                              onChange={this.handleInputChange}
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group unset-height">
                        <textarea
                          className="form-control form-required unset-height autosize"
                          name="message"
                          data-testid="message"
                          required={true}
                          value={this.state.inputValues.message}
                          onChange={this.handleInputChange}
                        />
                        <div className="required-placeholder unset-height">
                          {t('Contact.YourMessage')}
                          <span className="asterisk">*</span>
                        </div>
                      </div>
                      {/*  howDidYouHearAboutUs */}
                      <div className="form-group mb-0 mb-md-3">
                        <input
                          className="form-control"
                          type="text"
                          name="howDidYouHearAboutUs"
                          placeholder={t('Contact.HowDidYouHearAboutUs')}
                          value={this.state.inputValues.howDidYouHearAboutUs}
                          onChange={this.handleInputChange}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                        />
                      </div>
                      <div className="form-group mt-3 mt-md-0 mb-1 mb-md-3 text-left unset-height">
                        <input
                          className="form-control"
                          name="gdpr"
                          id={'contact-form-gdpr'}
                          required={true}
                          data-testid="gdpr"
                          checked={this.state.inputValues.gdpr}
                          onChange={this.handleInputChange}
                          type={'checkbox'}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                        />
                        <label htmlFor={'contact-form-gdpr'}>
                          <span className={'box'} /> {t('Contact.Gdpr.1')} <TranslatedLink useNativeLink={true} target={'_blank'} to={'/en/privacy-policy'}>{t('Contact.Gdpr.2')}</TranslatedLink>
                          <span className="asterisk">*</span>
                        </label>
                      </div>
                      <div className="form-group row mt-4 mt-md-5">
                        <div className={'col-md-6 d-md-flex text-left'}>

                          <HoverBtn id={'contact-btn'} type={'submit'} className={' mt-md-0 contact-btn'} size={1.25} handleClick={async (e) => {
                            e.preventDefault();
                            await this.submit()
                          }}>
                            <div>{t('Contact.Submit')}</div>
                          </HoverBtn>


                          {this.state.errorMessageSuccess &&
                            <div id="contact-btn" className={`btn ml-md-4 mt-3 mt-md-0 btn-hover contact-btn success`}>
                              {this.state.errorMessageSuccess ? t('Contact.ErrorMessages.Success') : t('Contact.Submit')}
                            </div>
                          }
                        </div>
                        <div className={'col-md-12'}>
                          <div className={`error-message mt-3 ${this.state.errorMessageSuccess ? 'success' : 'bg-danger text-white p-2'}`} style={{ opacity: this.state.errorMessage.length > 0 ? 1 : 0 }}>
                            {this.state.errorMessage}
                          </div>
                        </div>
                      </div>
                    </form>
              </div>
            </div>
            <div className="col-12 col-lg-6 formular-info">
              <div>
                  {this.props.noBackground ? <h1>{contact.title}</h1> : <h2>{contact.title}</h2>}
                  <p>{contact.text_paragraph}</p>
                  <LinkBtn color={'blue'} size={1}>
                    <TranslatedLink to="/en/brief">{t('Contact.FillOutForm')}</TranslatedLink>
                  </LinkBtn>
              </div>
            </div>
          
          </div>

          {this.props.showCompanyInfo && (
            <div className="container mt-5 pt-5 mt-md-0 pt-md-0">
              <div className="row contact-bottom">
                <div className="col-md-4">
                  <h3>{t('Contact.CompanyInfo.Title')}</h3>
                  <p dangerouslySetInnerHTML={{ __html: t('Contact.CompanyInfo.Text') }} />
                </div>
                <div className="col-md-4">
                  <h3>{t('Contact.Address.Title')}</h3>
                  <p dangerouslySetInnerHTML={{ __html: t('Contact.Address.Text') }} />
                </div>
                <div className="col-md-4">
                  <h3>{t('Contact.Contact.Title')}</h3>
                  <p dangerouslySetInnerHTML={{ __html: t('Contact.Contact.Text') }} />
                </div>
              </div>
            </div>
          )}
          </div>
      </section>
    )

  }
}


class ContactForm extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <LangContext.Consumer>
        {(lang) => (
          <StaticQuery
            query={graphql`{  contactSk: file(relativePath: {eq: "pages/sk/homepage/contact.md"}) {
              childMarkdownRemark {
                frontmatter {
                  title
                  text_paragraph
                }
              }
            }
            contactEn: file(relativePath: {eq: "pages/sk/homepage/contact.md"}) {
              childMarkdownRemark {
                frontmatter {
                  title
                  text_paragraph
                }
              }
            }
          }`}
            render={(data => <Form data={data} lang={lang} {...this.props} />)}
          />
        )}
      </LangContext.Consumer>
    )
  }
}

ContactForm.propTypes = {
  noBackground: PropTypes.bool,
  showCompanyInfo: PropTypes.bool,
}

export default ContactForm


